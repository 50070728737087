.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-form {
  padding: 15px;
  border: 1px solid rgba(51, 51, 51, .3);
  border-radius: 8px;
  box-shadow: 0px 8px 32px 0 rgba(249, 89, 89, .2);
  background-color: #fff;
}

.header-profile {
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.site-layout {
  /* overflow: auto; */
  max-height: 100vh;
}

.site-content-layout {
  overflow: auto;
  margin: 0 0 0 16px;
}

.site-header-layout {
  position: sticky;
  top: 0;
  z-index: 1009;
  box-shadow: 1px 1px 8px rgba(136, 136, 136, .6);
}

.questionnaire-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  padding-top: 10%;
  padding-bottom: 10%;
}

section.questionnaire-wrapper__welcome {
  height: 600px;
  width: calc(100% - 200px);
}

section.questionnaire-wrapper__welcome p {
  text-align: center;
  max-width: 70%;
}

@media only screen and (max-width: 764px) {
  section.questionnaire-wrapper__welcome {
    height: 600px;
    width: calc(100% - 80px);
  }

  section.questionnaire-wrapper__welcome p {
    text-align: center;
    max-width: none;
    line-height: 25px;
  }
}

.questionnaire-enter {
  opacity: 0;
  transform: translateX(200%);
}
.questionnaire-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.1s ease-in-out;
}

.questionnaire-form .ant-form-item-control-input {
  width: 100%;
}
.questionnaire-form .ant-form-item-control-input-content {
  flex: inherit;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.questionnaire-form .ant-form-item-explain-error {
  text-align: left;
}

@media only screen and (max-width: 764px) {
  .questionnaire-form {
    padding: 0 20px;
  }
}

.questionnaire-thankyou {
  height: 600px;
  width: calc(100% - 200px);
}

@media only screen and (max-width: 764px) {
  .questionnaire-thankyou {
    height: 600px;
    width: calc(100% - 80px);
  }
}

.questionnaire-success-enter {
  opacity: 0;
  transform: translateX(200%);
}
.questionnaire-success-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.1s ease-in-out;
}

.preview-html {
  position: fixed;
  right: 35px;
  bottom: 10%;
  z-index: 9999;
  width: 100px;
  height: 40px;
}

.preview-html button {
  background-color: #fff;
  color: #000;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 30, 255, .2);
  box-shadow: 1px 1px 1px 1px rgba(0, 30, 255, .2);
}

.mobile-preview-html {
  display: none;
}

@media only screen and (max-width: 764px) {
  .preview-html {
    display: none;
  }

  .mobile-preview-html {
    display: block;
  }
}